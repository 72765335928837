var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "equipGroupDetails-wrap" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "search" },
          [
            _c(
              "a-select",
              {
                staticStyle: { width: "120px" },
                attrs: { placeholder: "全部产品" },
                on: { change: _vm.productChange },
              },
              [
                _c("a-select-option", { attrs: { value: "" } }, [
                  _vm._v(" 全部产品 "),
                ]),
                _vm._l(_vm.productList, function (val) {
                  return _c(
                    "a-select-option",
                    { key: val.id, attrs: { value: val.code } },
                    [_vm._v(" " + _vm._s(val.productName) + " ")]
                  )
                }),
              ],
              2
            ),
            _c("a-input-search", {
              staticStyle: { width: "200px", "margin-left": "15px" },
              attrs: { placeholder: "请输入设备名称" },
              on: { search: _vm.onSearch },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "operation", staticStyle: { display: "flex" } },
          [
            !_vm.isCheckbox
              ? _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.isCheckbox = true
                      },
                    },
                  },
                  [_vm._v(" 批量移除设备 ")]
                )
              : _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        on: {
                          click: function ($event) {
                            _vm.isCheckbox = false
                          },
                        },
                      },
                      [_vm._v(" 取消 ")]
                    ),
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onDeleteBatch },
                      },
                      [_vm._v(" 确定 ")]
                    ),
                  ],
                  1
                ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "15px" },
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.addVisible = true
                  },
                },
              },
              [_vm._v(" 添加设备 ")]
            ),
          ],
          1
        ),
      ]),
      _c("a-table", {
        attrs: {
          "row-selection": _vm.isCheckbox
            ? {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              }
            : null,
          columns: _vm.columns,
          "data-source": _vm.tableData,
          "row-key": "id",
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function (text, record) {
              return [
                record.status === 0 ? _c("span", [_vm._v("未激活")]) : _vm._e(),
                record.status === 1 ? _c("span", [_vm._v("在线")]) : _vm._e(),
                record.status === 2 ? _c("span", [_vm._v("离线")]) : _vm._e(),
                record.status === 3 ? _c("span", [_vm._v("未知")]) : _vm._e(),
              ]
            },
          },
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    staticStyle: { "margin-right": "20px" },
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onLookGroup(record)
                      },
                    },
                  },
                  [_vm._v("查看")]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onRemoveGroup([record.id])
                      },
                    },
                  },
                  [_vm._v("移除分组")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("Pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowPage,
            expression: "isShowPage",
          },
        ],
        staticClass: "pagination",
        attrs: { "current-page": _vm.paramsList.page, count: _vm.total },
        on: { handleChange: _vm.changePage, handleChangeSize: _vm.changeSize },
      }),
      _c("AddDevice", {
        attrs: {
          productList: _vm.productList,
          groupId: _vm.paramsList.groupId,
        },
        on: { addSuccess: _vm.addSuccess },
        model: {
          value: _vm.addVisible,
          callback: function ($$v) {
            _vm.addVisible = $$v
          },
          expression: "addVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }