var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 800,
        title: "添加设备",
        visible: _vm.visible,
        "ok-text": "确认",
        "cancel-text": "取消",
      },
      on: {
        ok: _vm.onAddSubmit,
        cancel: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "product" },
        [
          _c("span", { staticStyle: { "margin-right": "15px" } }, [
            _vm._v("选择产品"),
          ]),
          _c(
            "a-select",
            {
              staticStyle: { width: "120px" },
              attrs: { placeholder: "全部产品" },
              on: { change: _vm.productChange },
            },
            _vm._l(_vm.productList, function (val) {
              return _c(
                "a-select-option",
                { key: val.id, attrs: { value: val.code } },
                [_vm._v(" " + _vm._s(val.productName) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { margin: "15px 0" } },
        [
          _c(
            "a-radio-group",
            {
              model: {
                value: _vm.isSelectAll,
                callback: function ($$v) {
                  _vm.isSelectAll = $$v
                },
                expression: "isSelectAll",
              },
            },
            [
              _c("a-radio", { attrs: { value: 1 } }, [
                _vm._v(" 选择指定设备 "),
              ]),
              _c("a-radio", { attrs: { value: 0 } }, [
                _vm._v(" 选择产品下全部设备 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isSelectAll === 1
        ? [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "设备名称" },
                    on: { change: _vm.deviceChange },
                  },
                  [
                    _c("a-select-option", { attrs: { value: -1 } }, [
                      _vm._v(" 全部设备 "),
                    ]),
                    _vm._l(_vm.deviceList, function (val) {
                      return _c(
                        "a-select-option",
                        { key: val.id, attrs: { value: val.id } },
                        [_vm._v(" " + _vm._s(val.deviceName) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c("a-input-search", {
                  staticStyle: { width: "200px", "margin-left": "15px" },
                  attrs: { placeholder: "请输入关键字搜索" },
                  on: { search: _vm.onSearch },
                }),
              ],
              1
            ),
            _c("a-table", {
              attrs: {
                columns: [
                  {
                    title: "设备名称",
                    dataIndex: "deviceName",
                    align: "center",
                  },
                ],
                "row-selection": {
                  selectedRowKeys: _vm.selectedRowKeys,
                  onChange: _vm.onSelectChange,
                },
                "row-key": "id",
                "data-source": _vm.tableData,
                pagination: { pageSize: 5 },
              },
            }),
            _c("span", { staticClass: "number" }, [
              _vm._v("选中 " + _vm._s(_vm.selectedRowKeys.length) + " 项"),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }