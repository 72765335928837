<template>
  <a-modal
    :width="800"
    title="添加设备"
    :visible="visible"
    ok-text="确认"
    cancel-text="取消"
    @ok="onAddSubmit"
    @cancel="visible = false"
  >
    <div class="product">
      <span style="margin-right:15px">选择产品</span>
      <a-select
        placeholder="全部产品"
        style="width: 120px"
        @change="productChange"
      >
        <a-select-option
          v-for="val in productList"
          :key="val.id"
          :value="val.code"
        >
          {{ val.productName }}
        </a-select-option>
      </a-select>
    </div>
    <div style="margin: 15px 0">
      <a-radio-group v-model="isSelectAll">
        <a-radio :value="1"> 选择指定设备 </a-radio>
        <a-radio :value="0"> 选择产品下全部设备 </a-radio>
      </a-radio-group>
    </div>
    <template v-if="isSelectAll === 1">
      <div style="margin-bottom: 10px">
        <a-select
          placeholder="设备名称"
          style="width: 120px"
          @change="deviceChange"
        >
          <a-select-option :value="-1">
            全部设备
          </a-select-option>
          <a-select-option
            v-for="val in deviceList"
            :key="val.id"
            :value="val.id"
          >
            {{ val.deviceName }}
          </a-select-option>
        </a-select>
        <a-input-search
          placeholder="请输入关键字搜索"
          style="width: 200px;margin-left:15px"
          @search="onSearch"
        />
      </div>
      <a-table
        :columns="[
          { title: '设备名称', dataIndex: 'deviceName', align: 'center' },
        ]"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        row-key="id"
        :data-source="tableData"
        :pagination="{ pageSize: 5 }"
      />
      <span class="number">选中 {{ selectedRowKeys.length }} 项</span>
    </template>
  </a-modal>
</template>

<script>
import { getDeviceData, addDeviceToGroup } from "@/api/equipGroup.js";
export default {
  model: {
    prop: "addVisible",
    event: "close",
  },
  props: {
    addVisible: Boolean,
    productList: Array,
    groupId: String,
  },
  data() {
    return {
      visible: false,
      isSelectAll: null, //是否选择产品下全部设备
      selectedRowKeys: [], //被选择的设备集合
      tableData: [], //表格数据
      paramsList: {
        //参数列表集合
        productCode: "",
        deviceName: "",
        page: 1,
        pageSize: 10000,
      },
      deviceList: [], //设备列表
    };
  },
  methods: {
    //获取设备列表
    getDeviceList() {
      getDeviceData(this.paramsList).then((res) => {
        this.deviceList = res.data.records;
        this.tableData = res.data.records;
      });
    },
    //执行添加操作
    onAddSubmit() {
      if (this.isSelectAll === 0) {
        const data = this.deviceList.map((item) => item.id);
        addDeviceToGroup(data, this.groupId).then((res) => {
          this.$emit("addSuccess");
        });
      } else {
        addDeviceToGroup(this.selectedRowKeys, this.groupId).then((res) => {
          if (res.success) this.$emit("addSuccess");
        });
      }
      this.visible = false;
    },
    //筛选表格数据方法
    setTableData(val) {
      this.paramsList.deviceName = val;
      getDeviceData(this.paramsList).then((res) => {
        // console.log("device", res);
        this.tableData = res.data.records;
      });
      this.paramsList.deviceName = "";
    },
    //切换产品回调
    productChange(val) {
      this.paramsList.productCode = val;
      this.getDeviceList();
    },
    //切换设备名称回调
    deviceChange(val) {
      if (val === -1) {
        this.setTableData("");
      } else {
        const device = this.deviceList.find((item) => item.id === val);
        this.setTableData(device.deviceName);
      }
    },
    //根据关键字搜索设备
    onSearch(val) {
      this.setTableData(val);
    },
    //选择项发生变化
    onSelectChange(val) {
      this.selectedRowKeys = val;
    },
  },
  watch: {
    addVisible(val) {
      this.visible = val;
    },
    visible(val) {
      if (!val) {
        this.$emit("close", false);
      }
    },
  },
};
</script>

<style scoped>
.number {
  position: relative;
  top: -50px;
}
</style>
