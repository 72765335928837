<template>
  <div class="equipGroupDetails-wrap">
    <div class="header">
      <div class="search">
        <a-select
          placeholder="全部产品"
          style="width: 120px"
          @change="productChange"
        >
          <a-select-option :value="''">
            全部产品
          </a-select-option>
          <a-select-option
            v-for="val in productList"
            :key="val.id"
            :value="val.code"
          >
            {{ val.productName }}
          </a-select-option>
        </a-select>
        <a-input-search
          placeholder="请输入设备名称"
          style="width: 200px;margin-left:15px"
          @search="onSearch"
        />
      </div>
      <div class="operation" style="display: flex">
        <a-button v-if="!isCheckbox" type="primary" @click="isCheckbox = true">
          批量移除设备
        </a-button>
        <div v-else>
          <a-button style="margin-right: 10px" @click="isCheckbox = false">
            取消
          </a-button>
          <a-button type="primary" @click="onDeleteBatch">
            确定
          </a-button>
        </div>
        <a-button
          style="margin-left: 15px"
          type="primary"
          @click="addVisible = true"
        >
          添加设备
        </a-button>
      </div>
    </div>
    <a-table
      :row-selection="
        isCheckbox
          ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange }
          : null
      "
      :columns="columns"
      :data-source="tableData"
      row-key="id"
      :pagination="false"
    >
      <template slot="status" slot-scope="text, record">
        <span v-if="record.status === 0">未激活</span>
        <span v-if="record.status === 1">在线</span>
        <span v-if="record.status === 2">离线</span>
        <span v-if="record.status === 3">未知</span>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a
          href="javascript:void(0);"
          style="margin-right: 20px"
          @click="onLookGroup(record)"
          >查看</a
        >
        <a href="javascript:void(0);" @click="onRemoveGroup([record.id])"
          >移除分组</a
        >
      </template>
    </a-table>
    <Pagination
      class="pagination"
      v-show="isShowPage"
      :current-page="paramsList.page"
      :count="total"
      @handleChange="changePage"
      @handleChangeSize="changeSize"
    />
    <AddDevice
      v-model="addVisible"
      :productList="productList"
      :groupId="paramsList.groupId"
      @addSuccess = 'addSuccess'
    />
  </div>
</template>

<script>
import { productList } from "@/api/product";
import { detailsColumns } from "./tableData.js";
import { getDeviceData, deviceGroupRemove } from "@/api/equipGroup.js";
import Pagination from "@/components/Pagination";
import AddDevice from "./components/AddDevice.vue";
export default {
  name: "EquipGroupDetails",
  components: {
    Pagination,
    AddDevice,
  },
  data() {
    return {
      addVisible: false, //添加设备模态表单是否打开
      isCheckbox: false, //是否显示批量选择复选框
      selectedRowKeys: [], //复选框选择集合
      productList: [], // 产品列表数据
      columns: detailsColumns,
      tableData: [], //表格数据
      //获取表格数据所需参数集合
      paramsList: {
        groupId: null, //分组id
        page: 1, //当前页码
        pageSize: null, //每页记录数
        deviceName: "", //设备名称
        deviceCode: "", //设备code
        productName: "", //所属产品名称
        productCode: "", //所属产品code
      },
      total: 0, //总条数
      isShowPage: true, //是否显示分页器
    };
  },
  created() {
    if (this.$route.query.id) {
      this.paramsList.groupId = this.$route.query.id;
      this.getTableData();
    }
    //获取产品列表
    productList()
      .then((res) => {
        this.productList = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    //根据分页查询接口获取表格数据
    getTableData() {
      getDeviceData(this.paramsList).then((res) => {
        if (res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    //根据设备或产品名称搜索分组
    onSearch(val) {
      this.paramsList.deviceName = val;
      this.getTableData();
      this.paramsList.deviceName = "";
    },
    //批量删除
    onDeleteBatch() {
      this.onRemoveGroup(this.selectedRowKeys, true);
    },
    //切换产品回调
    productChange(val) {
      this.paramsList.productCode = val;
      this.getTableData();
    },
    //移除分组方法
    onRemoveGroup(data, isBatch = false) {
      this.$confirm({
        title: "您确认移除设备吗？",
        okText: "确定",
        centered: true,
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          deviceGroupRemove(data)
            .then(() => {
              this.getTableData();
              this.$message.success("移除成功！");
              if (isBatch) this.isCheckbox = false;
            })
            .catch((error) => {
              console.log(error);
            });
        },
      });
    },
    //查看分组方法
    onLookGroup(data) {
      this.$router.push(`/equip-list/details?id=${data.id}`);
    },
    // 选择分页
    changePage(page) {
      this.paramsList.page = page;
      this.getTableData();
    },
    // 修改每页数据条数
    changeSize(size) {
      this.paramsList.pageSize = size;
      this.getTableData();
    },
    // 设备列表中的多选事件
    onSelectChange(val) {
      this.selectedRowKeys = val;
    },
    //添加设备成功
    addSuccess(){
      this.$message.success("添加设备成功");
      this.getTableData();
    }
  },
};
</script>

<style scoped lang="scss">
.equipGroupDetails-wrap {
  padding: 20px 40px;
  .header {
    // height: 70px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep .ant-pagination {
    text-align: right;
  }
  ::v-deep .ant-pagination-total-text {
    float: left;
  }
  .pagination {
    margin-top: 10px;
  }
}
</style>
